module.exports = [{
      plugin: require('/Users/fortune/dev/irminafabrizio.com/node_modules/gatsby-plugin-web-font-loader/gatsby-browser'),
      options: {"plugins":[],"google":{"families":["Clicker Script","Open Sans:400,300,700,800"]}},
    },{
      plugin: require('/Users/fortune/dev/irminafabrizio.com/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/fortune/dev/irminafabrizio.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
