// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/fortune/dev/irminafabrizio.com/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/Users/fortune/dev/irminafabrizio.com/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accommodation-kasprzak-js": () => import("/Users/fortune/dev/irminafabrizio.com/src/pages/accommodation-kasprzak.js" /* webpackChunkName: "component---src-pages-accommodation-kasprzak-js" */),
  "component---src-pages-accommodation-js": () => import("/Users/fortune/dev/irminafabrizio.com/src/pages/accommodation.js" /* webpackChunkName: "component---src-pages-accommodation-js" */),
  "component---src-pages-index-js": () => import("/Users/fortune/dev/irminafabrizio.com/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-schedule-js": () => import("/Users/fortune/dev/irminafabrizio.com/src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-wedding-location-js": () => import("/Users/fortune/dev/irminafabrizio.com/src/pages/wedding-location.js" /* webpackChunkName: "component---src-pages-wedding-location-js" */)
}

exports.data = () => import("/Users/fortune/dev/irminafabrizio.com/.cache/data.json")

